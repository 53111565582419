import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  graduationYear: yup.number().typeError('Graduation year must be a number').required('Graduation year is required'),
  phone: yup.string().required('WhatsApp number is required'),
  note: yup.string(),
});

const EnrollForm = ({ onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://streakifyapis-user-production.up.railway.app/user', data);
      console.log(response);
      setIsSubmitted(true);
      setTimeout(onClose, 3000); // Close the modal after 3 seconds
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Name</label>
            <input
              type="text"
              {...register('name')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.name ? 'border-red-500' : ''}`}
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Email</label>
            <input
              type="email"
              {...register('email')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.email ? 'border-red-500' : ''}`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Graduation Year</label>
            <input
              type="text"
              {...register('graduationYear')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.graduationYear ? 'border-red-500' : ''}`}
            />
            {errors.graduationYear && <p className="text-red-500 text-sm">{errors.graduationYear.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300">WhatsApp Number</label>
            <input
              type="text"
              {...register('phone')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.phone ? 'border-red-500' : ''}`}
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Note</label>
            <textarea
              {...register('note')}
              className="w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600"
            />
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </form>
      ) : (
        <div className="text-center py-10">
          <h2 className="text-2xl font-bold">Thank You!</h2>
          <p>Your enrollment has been submitted.</p>
        </div>
      )}
    </div>
  );
};

export default EnrollForm;
