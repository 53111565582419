import { useState } from 'react';

const Notification = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-blue-600 text-white p-4 text-center">
      <p>
      We are currently upgrading our site. Some functionalities might not work properly. Please reach out to us at <a href="mailto:info@streakify.in" className="underline">info@streakify.in</a> for any assistance.
      </p>
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-0 right-0 mt-2 mr-2 text-white hover:text-gray-300"
      >
        ✕
      </button>
    </div>
  );
};

export default Notification;
