import React from 'react';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
const Contact = () => {

    return (
        <div className="overflow-hidden w-full">
            {/* About Us Section */}
            <div className="relative bg-cover bg-center w-full h-[300px] lg:h-[300px]" style={{ backgroundImage: "url('/assets/img/ll.jpg')" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-sky-500 to-blue-500 opacity-35"></div>
                <div className="relative z-10 flex flex-col justify-center items-center text-center text-white py-16 w-full h-full">
                    <h1 className="text-4xl lg:text-5xl font-bold mb-4">Contact Us</h1>
                    <p className="text-sm lg:text-lg mb-8">Contact Us with Any Queries.</p>
                </div>
            </div>

            <div className="contactinner-section-area py-10 lg:py-20 bg-gray-100 dark:bg-slate-900 dark:text-white">
                <div className="container mx-auto px-4 py-12">
                    <div className="flex flex-col items-center text-center mb-8">
                        <div className="w-full lg:w-2/3">
                            <div className="contact5-textarea5">
                                <span className="font-outfit text-lg font-medium text-sky-600 dark:text-sky-300 inline-block mb-6">
                                    Contact Us
                                </span>
                                <h1 className="text-3xl lg:text-4xl font-bold mb-3 lg:mb-5">
                                    Contact Us for Expert Solutions and Innovative Courses.
                                </h1>
                                <p className="text-base lg:text-lg mb-3 lg:mb-5">
                                    At Streakify Technologies, we offer expert support to help you leverage the latest in technology solutions. Whether you need assistance with our products, have questions about our services, or require technical support, our dedicated team is here to help.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center gap-6">
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/call.svg" alt="Phone Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Call Us</p>
                                <a href="tel:+91-8105827558" className="text-xl font-bold">+91-8105827558</a>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/location.svg" alt="Location Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Visit Us</p>
                                <span className="text-xl font-bold">Bangalore, India-560034</span>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/mail1.svg" alt="Email Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Mail Us</p>
                                <a href="mailto:info@streakify.in" className="text-xl font-bold">info@streakify.in</a>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/chat.svg" alt="Chat Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Live Chat</p>
                                <span className="text-xl font-bold">Chat with Us 24/7</span>
                            </div>
                        </Card>
                    </div>
                </div>



            </div>

            <div className="w-full bg-gray-100 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-center">Office Locations</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-6">
                            <h2 className="text-lg font-semibold mb-2">Our Headquarters</h2>
                            <p className="text-sm dark:text-gray-300">
                                1st floor, SPD Plaza, 52, Jyoti Niwas College Rd,<br />
                                Koramangala Industrial Layout, Koramangala,<br />
                                Bengaluru, Karnataka - 560034<br />
                                Phone: +91-8105827558<br />
                                Email: info@streakify.in
                            </p>
                            <div className="mt-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.593650884204!2d77.61340807454606!3d12.933818215706554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1451d17a18a7%3A0xadc0e7c2d98ab83b!2sStreakify.io!5e0!3m2!1sen!2sin!4v1722089061502!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-6">
                            <h2 className="text-lg font-semibold mb-2">Our Hyderabad Office</h2>
                            <p className="text-sm dark:text-gray-300">

                                Flat No : G3, MJR Mansion, BJP Office Rd, <br />
                                Shanthi Nagar,
                                Kukatpally, Hyderabad, Telangana 500072<br />
                                Phone: +91-8105827558<br />
                                Email: info@streakify.in
                            </p>
                            <div className="mt-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.470443922777!2d78.4118614746291!3d17.485042299985594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9141d8578b83%3A0xffef9bc51efe7ca4!2sStreakify%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1722089252526!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="contactinner-section-area py-10 lg:py-20 bg-gray-100 dark:bg-slate-900 dark:text-white min-h-screen flex items-center justify-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-lg lg:text-3xl font-bold mb-10 text-center">
                        Fill out the form and our team will get back to you as soon as we can!
                    </h2>
                    <div className="flex flex-col lg:flex-row lg:gap-12 items-stretch justify-center max-w-6xl mx-auto">

                        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-center mb-8 lg:mb-0">
                            <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-6" style={{ maxWidth: '500px' }}>
                                <h2 className="text-lg lg:text-2xl font-bold mb-4 text-center">
                                    Looking for a Service?
                                </h2>
                                <p className="text-sm lg:text-base mb-4 text-center">
                                    Explore our range of services designed to elevate your business and meet your needs:
                                </p>
                                <ul className="list-disc list-inside text-sm lg:text-base mb-4 text-start space-y-6">
                                    <li className="hover:text-blue-500 hover:underline">
                                        <strong>Web Development:</strong>
                                    </li>
                                    <li className="hover:text-blue-500 hover:underline">
                                        <strong>Mobile App Development:</strong>
                                    </li>
                                    <li className="hover:text-blue-500 hover:underline">
                                        <strong>Graphic Designing:</strong>
                                    </li>
                                    <li className="hover:text-blue-500 hover:underline">
                                        <strong>Software Solutions:</strong>
                                    </li>
                                    <li className="hover:text-blue-500 hover:underline">
                                        <strong>Digital Marketing:</strong>
                                    </li>
                                </ul>

                                <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">
                                    <button type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center active:underline active:text-blue-500">
                                        Get Direction
                                    </button>
                                </a>
                            </div>
                        </div>


                        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-center">
                            <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-4 lg:p-6 flex flex-col justify-center h-full" style={{ maxWidth: '600px' }}>
                                <span className="text-2xl font-bold mb-4 block text-center">Connect With Streakify Team.</span>
                                <p className="text-sm lg:text-base mb-4 text-center">
                                    Fill out the form and our team will get back to you as soon as we can!
                                </p>
                                <form >
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium mb-1">Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium mb-1">Your Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="subject" className="block text-sm font-medium mb-1">Subject</label>
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="message" className="block text-sm font-medium mb-1">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows="4"
                                            className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
};

export default Contact;
