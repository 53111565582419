import React from 'react';
import { Card } from 'primereact/card';
const Refund = () => {
    return (
        <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
            <Card title="Terms and Conditions" className="w-full max-w-4xl">

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                    <p className="text-base">
                        Thank you for choosing our IT services and courses. We are dedicated to delivering quality and value. Please review our refund policy for details on eligibility and process.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Eligibility for Refunds</h2>
                    <ul className="list-disc pl-5 text-base">
                        <li><strong>IT Services:</strong> No Refunds: Once the service has commenced, no refunds will be provided.</li>
                        <li><strong>Courses:</strong> No Refunds: If the course has started or if the student does not appear for the course, no refunds will be provided.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Requesting a Refund</h2>
                    <p className="text-base">
                        To request a refund (where applicable):<br />
                        Contact our customer support team at <a href="mailto:info@streakify.in" className="text-blue-500">info@streakify.in</a><br />
                        Provide necessary details such as service or course information, and reason for the refund request.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Refund Process</h2>
                    <ul className="list-disc pl-5 text-base">
                        <li><strong>Approval:</strong> Refund requests will be reviewed and a decision will be communicated within 3-5 days.</li>
                        <li><strong>Processing Time:</strong> Approved refunds will be processed within 3-7 days. Refunds will be issued to the original payment method used at the time of purchase.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Non-Refundable Items</h2>
                    <ul className="list-disc pl-5 text-base">
                        <li><strong>IT Services:</strong> Services that have already commenced.</li>
                        <li><strong>Courses:</strong> Courses that have started or for which the student is absent.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
                    <p className="text-base">
                        For further questions or assistance, please contact our support team at:
                    </p>
                    <address className="text-base not-italic">
                        Streakify Technologies<br />
                        Bangalore, India-560034<br />
                        <a href="mailto:info@streakify.in" className="text-blue-500">info@streakify.in</a><br />
                        +91-8105827558
                    </address>
                </section>
            </Card>
        </div>

    );
};

export default Refund;
