import React from 'react';
import { Card } from 'primereact/card';

const Privacy = () => {
    return (
        <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
            <Card title="Privacy Policy" className=" w-full max-w-4xl">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">1. Introduction</h2>
                    <p className="text-base dark:text-gray-300">
                        Welcome to Streakify Technologies. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website streakify.in or use our services.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">2. Information We Collect</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Personal Information: Name, email address, phone number, payment details, and other information you provide.</li>
                        <li>Usage Data: Information about how you use our website, including your IP address, browser type, and pages visited.</li>
                        <li>Cookies and Tracking Technologies: We use cookies and similar technologies to enhance your experience on our website.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">3. How We Use Your Information</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>To process transactions and provide services.</li>
                        <li>To improve our website and services.</li>
                        <li>To communicate with you, including sending updates, marketing materials, and other relevant information.</li>
                        <li>To comply with legal obligations and enforce our terms and policies.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">4. Sharing Your Information</h2>
                    <p className="text-base dark:text-gray-300">
                        We do not sell or rent your personal information. We may share your information with:
                    </p>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Service Providers: Third parties who perform services on our behalf, such as payment processors or email service providers.</li>
                        <li>Legal Requirements: If required by law, to comply with legal processes or protect our rights.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">5. Security of Your Information</h2>
                    <p className="text-base dark:text-gray-300">
                        We implement security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">6. Your Rights</h2>
                    <p className="text-base dark:text-gray-300">
                        Depending on your location, you may have certain rights regarding your personal information, including:
                    </p>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Accessing or correcting your information.</li>
                        <li>Requesting deletion of your information.</li>
                        <li>Opting out of marketing communications.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">7. Cookies and Tracking Technologies</h2>
                    <p className="text-base dark:text-gray-300">
                        We use cookies and similar technologies to collect and store information about your preferences and activities. You can manage your cookie preferences through your browser settings.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">8. Third-Party Links</h2>
                    <p className="text-base dark:text-gray-300">
                        Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these external sites.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">9. Changes to This Privacy Policy</h2>
                    <p className="text-base dark:text-gray-300">
                        We may update this Privacy Policy from time to time. We will notify you of significant changes by posting the updated policy on our website.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">10. Contact Us</h2>
                    <p className="text-base dark:text-gray-300">
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </p>
                    <address className="text-base not-italic dark:text-gray-300">
                        Streakify Technologies<br />
                        Bangalore, India-560034<br />
                        <a href="mailto:info@streakify.in" className="text-blue-500 dark:text-blue-400">info@streakify.in</a><br />
                        +91-8105827558
                    </address>
                </section>
            </Card>
        </div>
    );
};

export default Privacy;
