// import React, { useState, useEffect } from 'react';
// import { FaRocket } from 'react-icons/fa';
// import 'primeicons/primeicons.css';  // Import PrimeIcons CSS
// import { Button } from 'primereact/button';
// const Footer = () => {
//     const [showScrollButton, setShowScrollButton] = useState(false);
//     const scrollToTop = () => {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     };

//     useEffect(() => {
//         const handleScroll = () => {
//             setShowScrollButton(window.scrollY > 300);
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, []);

//     return (
//         <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white relative bottom-0 left-0 w-full">
//             {/* Need Help Section */}
//             <div className="py-8">
//                 <div className="container mx-auto text-center">
//                     <h4 className="text-2xl font-semibold mb-4">"How Can We Assist You?"</h4>
//                     <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
//                         <div>
//                             <p className="mb-2">
//                                 Whether you’re stuck or just want some tips on where to start, hit up our experts anytime.
//                             </p>
//                             <p className="mb-2">
//                                 Sales Chat: MON-SAT 8AM-4PM IST | Customer Support Chat: Every Day 5:30AM–9:30PM IST
//                             </p>
//                         </div>
//                         <div className="mt-2">
//                             <Button label="Get in touch on Whatsapp" className="bg-slate-900 text-white hover:bg-sky-700 dark:bg-sky-800 dark:hover:bg-slate-600" />
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             {/* Footer Main Sections */}
//             <div className="container mx-auto py-12 px-6 md:px-12">
//                 <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
//                     {/* Heading */}
//                     <div className="space-y-4">
//                         {/* <h1 className="mt-4 text-4xl font-extrabold tracking-tight  sm:text-5xl xl:text-4xl">
//                             Streakify Technologies
//                         </h1> */}
//                     </div>

//                     <div className="space-y-4">
//                         <h4 className="text-xl font-semibold mb-4">SERVICES</h4>
//                         <ul className="space-y-2">
//                             <li><a href="#" className="hover:underline">Web Development</a></li>
//                             <li><a href="#" className="hover:underline">Mobile App Development</a></li>
//                             <li><a href="#" className="hover:underline">Graphic Designing</a></li>
//                             <li><a href="#" className="hover:underline">Software Solutions</a></li>
//                             <li><a href="#" className="hover:underline">Digital Marketing</a></li>
//                             <li><a href="#" className="hover:underline">UI/UX Design</a></li>
//                         </ul>
//                     </div>

//                     {/* Courses */}
//                     <div className="space-y-4">
//                         <h4 className="text-xl font-semibold mb-4">COURSES</h4>
//                         <ul className="space-y-2">
//                             <li><a href="mernstack.html" className="hover:underline">MERN Stack Development</a></li>
//                             <li><a href="reactnative.html" className="hover:underline">React Native</a></li>
//                         </ul>
//                     </div>

//                     {/* Links */}
//                     <div className="space-y-4">
//                         <h4 className="text-xl font-semibold mb-4">LINKS</h4>
//                         <ul className="space-y-2">
//                             <li><a href="/" className="hover:underline">Home</a></li>
//                             <li><a href="/about" className="hover:underline">About Us</a></li>
//                             <li><a href="/courses" className="hover:underline">Courses</a></li>
//                             <li><a href="/services" className="hover:underline">Services</a></li>
//                             <li><a href="/contact" className="hover:underline">Contact Us</a></li>
//                         </ul>
//                     </div>

//                     {/* Contact */}
//                     <div className="space-y-4">
//                         <h4 className="text-xl font-semibold mb-4">CONTACT</h4>
//                         <ul className="space-y-2">
//                             <li><a href="#" className="hover:underline">Contact Us</a></li>
//                             <li className="mt-2">
//                                 <span className="block">Follow Us:</span>
//                                 <div className="flex space-x-4 mt-2">
//                                     <a href="#" className="text-xl hover:text-gray-400">
//                                         <i className="pi pi-instagram"></i>
//                                     </a>
//                                     <a href="#" className="text-xl hover:text-gray-400">
//                                         <i className="pi pi-linkedin"></i>
//                                     </a>
//                                     <a href="#" className="text-xl hover:text-gray-400">
//                                         <i className="pi pi-twitter"></i>
//                                     </a>
//                                     <a href="#" className="text-xl hover:text-gray-400">
//                                         <i className="pi pi-facebook"></i>
//                                     </a>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>

//             {/* Footer Bottom */}
//             <div className=" py-3">
//                 <div className="container mx-auto text-center text-sm">
//                     <p className="mb-0">
//                         Copyrights © 2023. All rights reserved by <a href="#" target="_blank" className="underline text-sky-400">Streakify Technologies Private Limited</a>
//                     </p>
//                 </div>
//             </div>
//             {showScrollButton && (
//                 <button
//                     onClick={scrollToTop}
//                     className="fixed bottom-5 right-5 p-3 bg-sky-600 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none"
//                     aria-label="Scroll to Top"
//                 >
//                     <FaRocket className="text-white text-lg" />
//                 </button>
//             )}


//             {/* Policies Section */}
//             <div className=" py-3">
//                 <div className="container mx-auto text-center">

//                     <ul className="flex justify-center space-x-6">
//                         <li><a href="#" className="text-1xs text-gray-400 hover:underline">Refund Policy</a></li>
//                         <li><a href="#" className="text-gray-400 hover:underline">Privacy Policy</a></li>
//                         <li><a href="#" className="text-gray-400 hover:underline">Terms and Conditions</a></li>
//                     </ul>
//                 </div>
//             </div>
//         </footer>
//     );
// };

// export default Footer;
import React, { useState, useEffect } from 'react';
import { FaRocket } from 'react-icons/fa';
import 'primeicons/primeicons.css';  // Import PrimeIcons CSS
import { Button } from 'primereact/button';
import data from '../Service/data.json'
import Modal from './Modal';
import EnrollForm from './EnrollForm';

const Footer = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    
    const handleEnrollClick = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = "+918105827558"; // Replace with your WhatsApp number
        const message = "Hello, I would like to get more information about your services."; // Replace with your default message
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollButton(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white relative bottom-0 left-0 w-full">
            {/* Need Help Section */}
            <div className="py-8">
                <div className="container mx-auto text-center">
                    <h4 className="text-2xl font-semibold mb-4">"How Can We Assist You?"</h4>
                    <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
                        <div>
                            <p className="mb-2">
                                Whether you’re stuck or just want some tips on where to start, hit up our experts anytime.
                            </p>
                            <p className="mb-2">
                                Sales Chat: MON-SAT 8AM-4PM IST | Customer Support Chat: Every Day 5:30AM–9:30PM IST
                            </p>
                        </div>
                        <div className="mt-2">
                            <Button  onClick={handleWhatsAppClick} label="Get in touch on Whatsapp" className="bg-slate-900 text-white hover:bg-sky-700 dark:bg-sky-800 dark:hover:bg-slate-600" />
                        </div>
                    </div>
                </div>
            </div>
 
            {/* Footer Main Sections */}
            <div className="container mx-auto py-12 px-6 md:px-12">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
                    {/* Heading */}
                    <div className="space-y-4">
                        {/* <h1 className="mt-4 text-4xl font-extrabold tracking-tight  sm:text-5xl xl:text-4xl">
                            Streakify Technologies
                        </h1> */}
                    </div>

                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold mb-4">SERVICES</h4>
                        <ul className="space-y-2">
                            {data.services.map((service, index) => (
                                <li key={index}>
                                    <a href="/services" className="hover:underline">{service}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Courses */}
                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold mb-4">COURSES</h4>
                        <ul className="space-y-2">
                            {data.courses.map((course, index) => (
                                <li key={index}>
                                    <a href={course.link} className="hover:underline">{course.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Links */}
                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold mb-4">LINKS</h4>
                        <ul className="space-y-2">
                            <li><a href="/" className="hover:underline">Home</a></li>
                            <li><a href="/about" className="hover:underline">About Us</a></li>
                            <li><a href="/services" className="hover:underline">Services</a></li>
                            <li><a href="/careers" className="hover:underline">Careers</a></li>
                            <li><a href="/contact" className="hover:underline">Contact Us</a></li>
                        </ul>
                    </div>

                    {/* Contact */}
                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold mb-4">CONNECTION </h4>
                        <ul className="space-y-2">

                            <li className="mt-2">
                                <span className="block">Follow Us:</span>
                                <div className="flex space-x-4 mt-2">
                                    <a href="https://www.instagram.com/streakify.in/" className="text-xl hover:text-gray-400">
                                        <i className="pi pi-instagram"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/streakify/" className="text-xl hover:text-gray-400">
                                        <i className="pi pi-linkedin"></i>
                                    </a>
                                    <a href="https://x.com/Streakifyin" className="text-xl hover:text-gray-400">
                                        <i className="pi pi-twitter"></i>
                                    </a>
                                    <a href="https://www.facebook.com/streakify.in/" className="text-xl hover:text-gray-400">
                                        <i className="pi pi-facebook"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="py-3">
                <div className="container mx-auto text-center text-sm">
                    <p className="mb-0">
                        Copyrights © 2023. All rights reserved by <a href="#" target="_blank" className="underline text-sky-400">Streakify Technologies Private Limited</a>
                    </p>
                </div>
            </div>
            {showScrollButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-5 right-5 p-3 bg-sky-800 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none"
                    aria-label="Scroll to Top"
                >
                    <FaRocket className="text-white text-lg" />
                </button>
            )}

            {/* Policies Section */}
            <div className="py-3">
                <div className="container mx-auto text-center">
                    <ul className="flex justify-center space-x-6">
                        <li><a href="/refund" className="text-xs text-gray-400 hover:underline">Refund Policy</a></li>
                        <li><a href="/privacy" className=" text-xs text-gray-400 hover:underline">Privacy Policy</a></li>
                        <li><a href="/termsandconditions" className=" text-xs text-gray-400 hover:underline">Terms and Conditions</a></li>
                    </ul>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-2xl font-bold mb-4">Enroll in the Course</h2>
        <EnrollForm onClose={handleCloseModal}/>
      </Modal>
        </footer>
    );
};

export default Footer;
