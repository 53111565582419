import React,{useState} from 'react';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { TabMenu } from 'primereact/tabmenu';
import { Carousel } from 'primereact/carousel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { motion } from 'framer-motion';
import { Chart } from 'primereact/chart';
import Modal from '../components/Modal';
import EnrollForm from '../components/EnrollForm';

const Courses = () => {
  const salaryData = {
    labels: ["Minimum Salary", "Average Salary", "Maximum Salary"],
    datasets: [
      {
        label: "Annual Average Salaries (in Lakhs)",
        data: [10, 12, 28], // Minimum, Average, Maximum Salaries in lakhs
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  const handleEnrollClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // Data for Surge in Job Postings
  const jobPostingsData = {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "Job Postings Surge (%)",
        data: [20, 25, 30, 34, 36], // Surge in job postings from 2020 to 2024
        fill: false,
        borderColor: "rgba(153, 102, 255, 1)",
        tension: 0.1,
      },
    ],
  };

  // Data for Top Companies Hiring (Pie Chart Example)

  const tools = [
    { title: "GitHub", icon: "/assets/img/github.svg" },
    { title: "AWS", icon: "/assets/img/aws.svg" },
    { title: "Netlify", icon: "/assets/img/netlify.svg" },
    { title: "Git", icon: "/assets/img/git.svg" },
    { title: "CircleCI", icon: "/assets/img/circleci.svg" },
  ];

  const skills = [
    { icon: "/assets/img/html.png", title: "HTML" },
    { icon: "/assets/img/css.png", title: "CSS" },
    { icon: "/assets/img/n2.png", title: "JavaScript" },
    { icon: "/assets/img/react.svg", title: "React" },
    { icon: "/assets/img/n1.png", title: "Redux" },
    { icon: "/assets/img/node-js.svg", title: "Node.js" },
    { icon: "/assets/img/n3.png", title: "Express.js" },
    { icon: "/assets/img/mongodb.svg", title: "MongoDB" },
  ];

  const faqCategories = [
    "Eligibility & Application",
    "Program prerequisites",
    "Payment",
    "Start date & duration",
    "Job Placement",
    "Certifications",
    "Course",
    "Mentorship",
    "MERN Stack",
  ];
  const steps = [
    {
      title: "Study",
      description:
        "Live expert-led sessions, Curated learning resources, Real-world case studies",
    },
    { title: "Make", description: "Hands-on projects, Practical exercises" },
    { title: "Attempt", description: "Quizzes, Assignments" },
    { title: "Revise", description: "Review sessions, Additional resources" },
    { title: "Test", description: "Final assessments, Certification exams" },
  ];
  const faqs = [
    {
      question: "How do I get my doubts resolved?",
      answer:
        "You can get your doubts resolved by contacting our support team via email at info@streakify.in or by using the live chat feature on our website.",
    },

    {
      question:
        "Where will the classes be conducted? What are the course timings?",
      answer:
        "Classes are conducted online/offline through our interactive platform. Course timings are flexible, allowing you to learn at your own pace.",
    },

    {
      question: "How will I benefit from Industry Experts?",
      answer:
        "Our courses include sessions with industry experts who provide valuable insights, mentorship, and real-world knowledge to help you succeed in your career.",
    },
    {
      question:
        "Do I need a computer science background to enroll for this course?",
      answer:
        "No, you do not need a computer science background. Our courses are designed for learners of all levels, including beginners.",
    },
    {
      question:
        "Is there a guarantee that I will get an internship or job after taking this course?",
      answer:
        "While we do not guarantee internships or jobs, our comprehensive training and placement support significantly increase your chances of securing opportunities.",
    },

    {
      question: "Can I do this program along with my college?",
      answer:
        "Yes, our courses are designed to be flexible, allowing you to manage both your studies and our program simultaneously.",
    },

    {
      question:
        "When will I receive my Co-Branded Course Participation certificate?",
      answer:
        "You will receive your certificate within two weeks of completing the course requirements.",
    },

    {
      question:
        "My course is complete and I am unable to access all my content. What should I do?",
      answer:
        "Please contact our support team to resolve access issues. We ensure all completed course materials remain accessible to our students.",
    },

    {
      question: "In which language are the courses taught?",
      answer:
        "Our courses are primarily taught in English. However, we offer support in multiple languages to assist learners from different regions.",
    },
    {
      question: "Which tech roles will I be eligible for after the course?",
      answer:
        "Upon completion, you will be eligible for various tech roles such as Software Developer, Backend Developer Web Developer, and more.",
    },

    {
      question:
        "How many domain expert sessions & mock interviews will I have?",
      answer:
        "You will have at least 5 domain expert sessions and 3 mock interviews during the course to prepare you for the industry.",
    },
  ];
  const options = [
    {
      image: "/path-to-mern-icon.png",
      title: "MERN Stack Developer",
      description:
        "Master all layers of web development using MongoDB, Express, React, and Node.js.",
    },
    {
      image: "/path-to-react-icon.png",
      title: "React Frontend Developer",
      description:
        "Specialize in creating dynamic and responsive user interfaces with React.",
    },
    {
      image: "/path-to-node-icon.png",
      title: "Node.js Backend Developer",
      description:
        "Build scalable server-side applications and APIs using Node.js and Express.",
    },
    {
      image: "/path-to-mongodb-icon.png",
      title: "MongoDB Database Engineer",
      description:
        "Design and manage NoSQL databases to store and retrieve data efficiently.",
    },
  ];

  const menuItems = [{ label: "Guaranteed Placement" }];
  const categories = [
    "Big brands",
    "Work from home",
    "Part-time",
    "MBA",
    "Engineering",
    "Media",
    "Design",
    "Data Science",
  ];

  const CareerOption = ({ image, title, description }) => (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <img src={image} alt={title} className="w-16 h-16 mb-4" />
      <h3 className="font-bold text-lg mb-2">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  );
  return (
    <div className="relative overflow-hidden pb-16">
      <section className="relative overflow-hidden pb-16 bg-white dark:bg-slate-900 dark:text-white">
        {/* Section Wrapper for Main Content */}
        <div className="relative z-10 flex flex-col items-start text-left text-slate-700 w-full h-full py-16 px-8 md:px-16 lg:px-24">
          {/* Main Content */}
          <div className="relative z-10 flex flex-col lg:flex-row items-start w-full">
            <div className="lg:w-1/2 lg:pr-8">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 dark:text-gray-300 text-center lg:text-left">
                MERN STACK DEVELOPMENT COURSE
              </h1>

              <p className="text-lg md:text-xl font-semibold mb-4 md:mb-8 text-center lg:text-left">
                Join our MERN Stack Development course with guaranteed placement
                and comprehensive internship opportunities.
              </p>
              <div className="flex flex-wrap justify-center lg:justify-start space-x-6 mb-6">
                <div className="flex space-x-4 mb-4">
                  <img
                    src="/assets/img/mongodb.svg"
                    alt="MongoDB"
                    className="h-10 md:h-12"
                  />
                  <img
                    src="/assets/img/express.png"
                    alt="Express.js"
                    className="h-10 md:h-12"
                  />
                </div>
                <div className="flex space-x-4">
                  <img
                    src="/assets/img/react.svg"
                    alt="React"
                    className="h-10 md:h-12"
                  />
                  <img
                    src="/assets/img/node-js.svg"
                    alt="Node.js"
                    className="h-10 md:h-12"
                  />
                </div>
              </div>
              <div className="mb-4 md:mb-8 flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
                {/* <button
                  type="button"
                  className="w-full md:w-auto text-blue-600 border border-sky-600 hover:bg-sky-600 hover:text-white focus:ring-2 focus:outline-none focus:ring-sky-200 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center"
                >
                  Download Curriculum
                  <i className="pi pi-chevron-down ml-2"></i>
                </button> */}
                <button
                 onClick={openModal}
                  type="button"
                  className="w-full md:w-auto text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center"
                >
                  Enroll Now
                  <i className="pi pi-chevron-down ml-2"></i>
                </button>
              </div>
            </div>
            {/* Image */}
            <div className="relative lg:w-1/2 flex justify-center lg:justify-end lg:ml-8 lg:mt-8 mt-4 hidden lg:block">
              <img
                src="/assets/img/hero.png"
                alt="MERN Stack Development"
                className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl transform -translate-y-16 -translate-x-2"
              />
            </div>
          </div>

          <div className="absolute top-2  space-x-4 flex flex-wrap lg:space-x-6">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-sky-400 via-teal-450 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              AI Powered
            </button>
          </div>

          <div className="flex flex-wrap justify-center gap-4 mt-6 bg-gray-100 dark:bg-gray-900 p-4 rounded-lg border-t-pink-400 border-r-teal-600 border-b-teal-600 border-l-pink-400 max-h-[30%] dark:text-gray-300 py-12">
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600   text-white mr-4">
                <i
                  className="pi pi-calendar"
                  style={{ fontSize: "1.25rem" }}
                ></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Online/Offline
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  Flexible learning options
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600   text-white mr-4">
                <i className="pi pi-clock" style={{ fontSize: "1.25rem" }}></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Total Curriculum Duration
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  6 Months
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600   text-white mr-4">
                <i
                  className="pi pi-calendar-times"
                  style={{ fontSize: "1.25rem" }}
                ></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Course Duration
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  3 Months
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600  text-white mr-4">
                <i
                  className="pi pi-calendar-times"
                  style={{ fontSize: "1.25rem" }}
                ></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Internship Duration
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  3 Months
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600  text-white mr-4">
                <i className="pi pi-check" style={{ fontSize: "1.25rem" }}></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Certificate
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  Completion certificate provided
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600  text-white mr-4">
                <i
                  className="pi pi-briefcase"
                  style={{ fontSize: "1.25rem" }}
                ></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  100% Placement Assistance
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  We help you land your dream job
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-blue-600  text-white mr-4">
                <i className="pi pi-mobile" style={{ fontSize: "1.25rem" }}></i>{" "}
                {/* Unique icon */}
              </div>
              <div>
                <div className="font-bold text-slate-700 dark:text-gray-300">
                  Dedicated Support
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  24/7 support for students
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-2xl font-bold mb-4">Enroll in the Course</h2>
        <EnrollForm onClose={handleCloseModal}/>
      </Modal>

      <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900 py-12">
        <div className="max-w-6xl mx-auto px-4">
          {/* MERN Stack Overview */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
              MERN Stack Developer Course Overview
              <span className="block h-1 w-24 bg-sky-500 mt-2"></span>
            </h2>
            <div className="flex flex-wrap items-center mb-8">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <img
                  src="/assets/img/mern-fullstack-img-12.png"
                  alt="MERN Stack"
                  className="w-full h-auto object-cover"
                />
              </div>
              <div className="w-full md:w-1/2">
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  Enroll in Unique Technologies' premier MERN Stack Developer
                  Course, which covers a complete range of topics including
                  HTML, CSS, JavaScript, JSON, Bootstrap, React JS, Redux, Node
                  JS, Express JS, and MongoDB.
                </p>

                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  MERN stands for MongoDB (database), Express JS (framework),
                  React JS (client-side framework), and Node JS (web server). In
                  our course, you will learn how to build front-end, back-end,
                  and database entirely in JavaScript and JSON.
                </p>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  MERN is a full-stack platform based on the 3-tier architecture
                  model. We offer the best MERN Stack Developer Course,
                  providing you with the skills to develop web applications
                  using React JS, Express JS, Node JS, and MongoDB.
                </p>
              </div>
            </div>
          </section>

          {/* Scope of MERN Stack Course */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
              Scope of Our MERN Stack Developer
              <span className="block h-1 w-24 bg-sky-500 mt-2"></span>
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Become a certified MERN Stack Developer by enrolling in our course
              and start developing web applications using frontend, database,
              and backend technologies. MERN stack developers are in great
              demand, and React JS and Node JS are trending technologies
              resulting in higher packages offered by companies.
            </p>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              The MERN stack offers a great user experience, making MERN Stack
              Developers full-fledged web application developers with an ocean
              full of opportunities.
            </p>
          </section>

          {/* Insights on Training Program */}
          <section>
            <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
              MERN Stack Developer Course: Insights on Training Program
              <span className="block h-1 w-24 bg-sky-500 mt-2"></span>
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Our MERN Stack Developer Course prepares you to update your skills
              and increase your knowledge about Node JS and other programming
              languages. We provide brief learning of different modules to the
              students for a better learning experience, making our training
              program one of the best.
            </p>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              In our course, you will learn:
            </p>
            <ul className="list-disc list-inside text-gray-600 dark:text-gray-400">
              <li>Constructing complex user interfaces using React JS</li>
              <li>Creating web applications using JavaScript</li>
              <li>Utilizing MongoDB, Node JS, and Express JS</li>
              <li>Designing and building web applications fast and easily</li>
            </ul>
          </section>

          <div className="flex flex-wrap items-center justify-between py-12">
            <div className="w-full md:w-1/2 px-4 mb-8">
              <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
                Why Learn the MERN Stack?
                <span className="block h-1 w-24 bg-sky-500 mt-2"></span>
              </h2>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-4">
                <li>
                  Comprehensive Technology Stack: Build applications with
                  MongoDB, Express.js, React.js, and Node.js.
                </li>
                <li>
                  High Demand: MERN Stack developers are sought after in the job
                  market.
                </li>
                <li>
                  Full Stack Development: Gain skills for both frontend and
                  backend development.
                </li>
                <li>
                  JavaScript Everywhere: Use a single language for the entire
                  stack.
                </li>
                <li>
                  Scalability: Create scalable and efficient applications.
                </li>
                <li>
                  Strong Community Support: Access extensive resources and
                  community help.
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="flex flex-wrap justify-start space-x-5 mb-6">
                  <div className="flex space-x-10 mb-4">
                    <img
                      src="/assets/img/mongodb.svg"
                      alt="MongoDB"
                      className="h-24 md:h-20"
                    />
                    <img
                      src="/assets/img/express.png"
                      alt="Express.js"
                      className="h-24 md:h-20"
                    />
                  </div>
                  <div className="flex space-x-6 mb-8">
                    <img
                      src="/assets/img/react.svg"
                      alt="React"
                      className="h-24 md:h-20"
                    />
                    <img
                      src="/assets/img/node-js.svg"
                      alt="Node.js"
                      className="h-24 md:h-20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 py-12 min-h-screen">
        <div className="container mx-auto px-4 lg:px-8">
          <h2 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-200 text-left">
            The Significance of MERN Stack Development
            <span className="block h-1 w-24 bg-sky-500 mt-2"></span>
          </h2>
          <section className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 mb-8">
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Our MERN Stack Development Courses are designed to provide a
              comprehensive understanding of modern web development
              technologies. Covering MongoDB, Express.js, React.js, and Node.js,
              this course equips you with the skills to build robust and
              scalable web applications. By the end of the program, you'll be
              adept at creating full-stack solutions and prepared to make an
              impact in the tech industry.
            </p>
            <p className="text-2xl text-sky-700 dark:text-gray-300 mb-4">
              Key Benefits:
            </p>
            <ul className="list-disc list-inside text-left text-gray-700 dark:text-gray-300 space-y-2">
              <li>
                <strong>In-Depth MERN Stack Training:</strong> Gain expertise in
                MongoDB, Express.js, React.js, and Node.js.
              </li>
              <li>
                <strong>Project-Based Learning:</strong> Develop real-world
                applications to enhance your practical skills.
              </li>
              <li>
                <strong>Career Opportunities:</strong> Open doors to various
                roles such as Full Stack Developer, Front-End Developer, and
                Back-End Developer.
              </li>
              <li>
                <strong>Up-to-Date Curriculum:</strong> Learn the latest
                industry practices and technologies from experienced
                instructors.
              </li>
            </ul>
          </section>

          {/* Annual Average Salaries and Surge in Job Postings Sections */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
            {/* Annual Average Salaries Section */}
            <section className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                Annual Average Salaries for MERN Stack Developers (2024)
              </h2>
              <div className="relative">
                <div className="overflow-x-auto">
                  <Chart
                    type="bar"
                    data={salaryData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: { position: "top" },
                        title: {
                          display: true,
                          text: "Annual Average Salaries for MERN Stack Developers (2024)",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </section>

            {/* Surge in Job Postings Section */}
            <section className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                Surge in Job Postings for MERN Stack Developers (2020-2024)
              </h2>
              <div className="relative">
                <div className="overflow-x-auto">
                  <Chart
                    type="line"
                    data={jobPostingsData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: { position: "top" },
                        title: {
                          display: true,
                          text: "Surge in Job Postings for MERN Stack Developers (2020-2024)",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <section className="py-12 bg-gray-50 dark:bg-gray-900">
        <div className="max-w-5xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-200 text-center">
            How Your Training Journey Unfolds
            <span className="block h-1 w-24 bg-sky-500 mt-2 mx-auto"></span>
          </h2>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                <img
                  src="/assets/img/learn1.svg"
                  alt="Learn Concepts"
                  className="w-full h-40 object-cover mb-4 rounded-t-lg"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
                    Learn Concepts
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Go through training videos to learn key concepts of the MERN
                    Stack. Our well-structured content ensures comprehensive
                    understanding.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                <img
                  src="/assets/img/test1.svg"
                  alt="Test Yourself"
                  className="w-full h-40 object-cover mb-4 rounded-t-lg"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
                    Test Yourself
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Assess your knowledge through quizzes and module tests at
                    regular intervals. This will help reinforce your learning
                    and track your progress.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                <img
                  src="/assets/img/hands1.svg"
                  alt="Hands-on Practice"
                  className="w-full h-40 object-cover mb-4 rounded-t-lg"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
                    Hands-on Practice
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Work on assignments and projects using our in-browser IDE
                    for coding practice. This hands-on approach helps solidify
                    your understanding and application of concepts.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                <img
                  src="/assets/img/doubt1.svg"
                  alt="Doubt Solving"
                  className="w-full h-40 object-cover mb-4 rounded-t-lg"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
                    Doubt Solving
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Get your doubts solved by experts through our Q&A forum
                    within 24 hours. Personalized support ensures you stay on
                    track with your learning.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                <img
                  src="/assets/img/certification1.svg"
                  alt="Receive Certification"
                  className="w-full h-40 object-cover mb-4 rounded-t-lg"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
                    Receive Certification
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    After passing the final exam, receive a certificate
                    recognizing your proficiency in the MERN Stack. Add this
                    credential to your resume and LinkedIn profile.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-100 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto px-4">
          {" "}
          {/* Reduced max width */}
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
              Skills You Will Learn
              <span className="block h-1 w-24 bg-sky-500 mt-2 mx-auto"></span>
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900"
              >
                <div className="flex-shrink-0 w-10 h-10 bg-sky-100 dark:bg-sky-700 rounded-full flex items-center justify-center mb-4 mx-auto">
                  {" "}
                  {/* Reduced size */}
                  <img
                    src={skill.icon}
                    alt={skill.title}
                    className="w-8 h-8 text-sky-500"
                  />{" "}
                  {/* Reduced size */}
                </div>
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {skill.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-12 bg-gray-100 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
              Tools You Will Learn And Use
              <span className="block h-1 w-24 bg-sky-500 mt-2 mx-auto"></span>
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {tools.map((tool, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900"
              >
                <div className="flex-shrink-0 w-10 h-10 bg-sky-100 dark:bg-sky-700 rounded-full flex items-center justify-center mb-4 mx-auto">
                  <img
                    src={tool.icon}
                    alt={tool.title}
                    className="w-8 h-8 text-sky-500"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {tool.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
        <div className="w-full max-w-6xl">
          <div className="flex flex-col md:flex-row bg-white dark:bg-gray-900 p-10 ">
            <div className="md:w-1/2 pr-8">
              <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-100">
                Earn a career certificate
                <span className="block h-1 w-24 bg-sky-500 mt-2 dark:bg-sky-400"></span>
              </h2>
              <p className="text-gray-600 mb-8 dark:text-gray-300">
                Earn your certification that validates your skills and readiness
                for the industry once you complete your projects and pass the
                final assessment.
              </p>

              <div className="space-y-6">
                {[
                  {
                    icon: "/assets/img/certi.png",
                    title: "Industry-Ready Certification",
                    description:
                      "Our certification proves your job readiness, distinguishing you from other candidates.",
                  },
                  {
                    icon: "/assets/img/share.svg",
                    title: "Shareable and Credible",
                    description:
                      "Easily add it to your LinkedIn, share on social media, or include it in your resume to enhance your profile.",
                  },
                  {
                    icon: "/assets/img/search.svg",
                    title: "Stand Out to Employers",
                    description:
                      "This certification can attract higher salary offers and opportunities by showcasing your industry readiness.",
                  },
                ].map((item, index) => (
                  <div key={index} className="flex items-start mb-6 md:mb-8">
                    <div className="w-16 h-16 md:w-16 md:h-16 flex items-center justify-center mr-4">
                      <img
                        src={item.icon}
                        alt={item.title}
                        className="w-14 h-14 md:w-12 md:h-12"
                      />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg text-gray-800 dark:text-gray-100">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8">
                <button
                  type="button"
                  className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Book Your Session
                </button>
              </div>
            </div>

            <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center items-center">
              <div className="w-64 h-64 md:w-80 md:h-80  flex items-center justify-center ">
                <img
                  src="/assets/img/cc1.png"
                  alt="Certificate"
                  className="w-6/27 h-3/5 "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto p-8   rounded-lg">
        <h2 className="text-2xl font-bold mb-6   dark:text-white text-gray-800">
          Frequently Asked Questions
        </h2>

        <TabView>
          <TabPanel>
            <Accordion multiple className="border-t-1 border-sky-500">
              {faqs.map((item, index) => (
                <AccordionTab
                  key={index}
                  header={item.question}
                  className="border-b-2 border-sky-700"
                >
                  <p className="text-gray-600">{item.answer}</p>
                </AccordionTab>
              ))}
            </Accordion>
          </TabPanel>
        </TabView>
      </div>

      <section className="relative min-h-screen bg-gray-100 dark:bg-slate-900 dark:text-white flex items-center overflow-hidden pb-16">
        <div className="container mx-auto flex flex-wrap">
          {/* Left Side: Content */}
          <div className="w-full lg:w-1/2 px-4 lg:px-8 mb-8 lg:mb-0 flex items-center">
            <div className="text-center lg:text-left">
              <h2 className="text-4xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                Need More Information? We're Here to Help You Out!
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                We provide personalized assistance to address all your queries
                and guide you through the process. Feel free to reach out, and
                our team will be happy to assist you with your concerns and
                provide the information you need.
                <br />
                <br />
                Our team will be contacting you within 24 hours to answer any
                questions you may have and provide additional details!
              </p>
            </div>
          </div>

          {/* Right Side: Form */}
          <div className="w-full lg:w-1/2 px-4 lg:px-8">
            <div className="bg-white dark:bg-slate-800 p-8 shadow-lg rounded-lg">
              <form>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Your Name *
                  </label>
                  <InputText
                    id="name"
                    placeholder="Your Name"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="whatsapp"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Your Whatsapp Number *
                  </label>
                  <InputText
                    id="whatsapp"
                    placeholder="Your Whatsapp Number"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Email *
                  </label>
                  <InputText
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                    required
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Contact Us
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Courses;
