import React from 'react';
import { Card } from 'primereact/card';
import ServiceCard from '../components/ServiceCard';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import FilterTags from '../components/FilterTag';
const Career = () => {

    const internships = [
        {
            title: "Digital Marketing Excutive(Female)",
            company: "",
            location: "Hyderabad",
            salary: "₹15,000-₹20,000/month",
            duration: "",
            isActivelyHiring: false,
            jobType: 'Full Time',
            workType: 'OnSite'
        },
        {
          title: "Interns-ReactJS",
          company: "",
          location: "Hyderabad",
          salary: "",
          duration: "3 Months",
          isActivelyHiring: true,
          jobType: 'Internship',
          workType: 'Remote'
      },

        
    ];

    // Add more job objects as needed

    const services = [
        {
            title: 'Casual Attire Encouraged',
            description: 'It’s not the clothes that change the world, but the people who wear them',
            image: '/assets/img/dress.svg'
        },
        {
            title: 'Efficient and Dynamic Teams',
            description: 'Our streamlined teams enable us to be innovative, outstanding, and boundless.',
            image: '/assets/img/team.svg'
        },
        {
            title: 'Attractive Compensation Packages',
            description: 'Pursue your passion without financial stress. We offer competitive salaries to ensure your peace of mind.',
            image: '/assets/img/package.svg'
        },

    ];

    return (
      <div className="bg-gray-50 p-4">
        <div className="max-w-6xl mx-auto px-4 py-10">
          <h2 className="text-3xl font-bold mb-6 text-center">
            Openings at{" "}
            <span className="text-sky-900 dark:text-white">
              {" "}
              Streakify Technologies
            </span>
          </h2>

          {/* <FilterTags /> */}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {internships.map((internship, index) => (
              <Card
                key={index}
                className="shadow-md p-4 relative h-full hover:bg-sky-100 dark:bg-slate-900 dark:hover:bg-slate-600"
              >
                {/* Actively Hiring Tag */}
                {internship.isActivelyHiring && (
                  <Tag
                    value="Actively hiring"
                    severity="success"
                    className="thin-tag absolute top-2 left-2 mb-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 font-medium rounded-lg text-sm px-3 py-1.5"
                  />
                )}

                <div className="absolute top-2 right-2">
                  <i className="pi pi-star text-gray-400 hover:text-yellow-500 cursor-pointer" />
                </div>

                <h3 className="text-xl font-semibold mb-1">
                  {internship.title}
                </h3>
                <p className="text-sm text-gray-600 mb-3">
                  {internship.company}
                </p>
                <div className="flex items-center mb-1">
                  <i className="pi pi-map-marker mr-2 text-gray-500" />
                  <span className="text-sm">{internship.location}</span>
                </div>
                {/* Show Salary if it is Greater Than Zero */}
                {internship.salary && internship.salary !== "$0/month" && (
                  <div className="flex items-center mb-1">
                    <i className="pi pi-money-bill mr-2 text-gray-500" />
                    <span className="text-sm">{internship.salary}</span>
                  </div>
                )}
                {/* Show Duration if Job Type is Internship */}
                {internship.jobType === "Internship" && (
                  <div className="flex items-center mb-4">
                    <i className="pi pi-calendar mr-2 text-gray-500" />
                    <span className="text-sm">{internship.duration}</span>
                  </div>
                )}
                <div className="flex items-center mb-4 mr-2">
                  <Tag
                    value={internship.workType}
                    severity="warning"
                    className="mb-2 thin-tag text-white bg-gradient-to-r from-orange-500 via-orange-600 to-orange-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-800 font-medium rounded-lg text-sm px-5 py-2.5"
                  />
                  <Tag
                    value={internship.jobType}
                    className="mb-2 ml-2 thin-tag text-white bg-gradient-to-r from-blue-start to-blue-end hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-start dark:focus:ring-blue-end font-medium rounded-lg text-sm px-5 py-2.5"
                  />
                </div>

                <div className="flex justify-between items-center mt-auto"></div>
              </Card>
            ))}
          </div>

          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg my-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              How to Apply
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              Ready to join our team? Apply by sending your resume to 
              <a
                href="mailto:info@streakify.in"
                className="text-blue-500 dark:text-blue-300"
              >
                info@streakify.in
              </a>
              . We look forward to hearing from you!
            </p>
          </div>

          <div className="flex justify-between items-center mt-8">
            <button
              type="button"
              class="text-white bg-gradient-to-r from-orange-500 via-orange-600 to-orange-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              {" "}
              <a
                href="mailto:info@streakify.in"
                className="text-blue-500 dark:text-blue-300"
              ></a>
              Apply Now
            </button>
          </div>
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg my-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Recruitment Fraud Alert
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              Please note that neither Streakify Technologies nor any of its
              authorized recruitment agencies/partners charge any candidate
              registration fee or any other fees from talent (candidates)
              towards appearing for an interview or securing
              employment/internship. Candidates shall be solely responsible for
              verifying the credentials of any agency/consultant that claims to
              be working with Streakify Technologies for recruitment. Anyone who
              relies on the representations made by fraudulent employment
              agencies does so at their own risk, and Streakify Technologies
              disclaims any liability in case of loss or damage suffered as a
              consequence of the same.
            </p>
          </div>
        </div>
        <section className="relative overflow-hidden py-20 px-4 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8 text-slate-900 dark:text-white">
              <span className="text-sky-900 dark:text-white">
                {" "}
                Benefits of Joining Us
              </span>
            </h2>
            <p className="text-lg mb-12 text-gray-700 dark:text-gray-300">
              Efficiently design integrated solutions and build strategic
              networks for advanced results.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <ServiceCard key={index} {...service} />
              ))}
            </div>
          </div>
        </section>
      </div>
    );
};

export default Career;
