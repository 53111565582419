import React, { useState } from 'react';
import { Tag } from 'primereact/tag';

const FilterTags = () => {
    // Set 'All' as the default selected job type
    const [selectedJobType, setSelectedJobType] = useState('All');

    const jobTypes = ['Internship', 'Full-time', 'Contract'];

    return (
        <div className="flex flex-wrap justify-center gap-2 p-4">
            <Tag
                key="All"
                value="All"
                className={`cursor-pointer ${selectedJobType === 'All' ? 'bg-teal-500 text-white' : 'bg-sky-100 text-teal-800'}`}
                onClick={() => setSelectedJobType(selectedJobType === 'All' ? null : 'All')}
            />
            {jobTypes.map((jobType) => (
                <Tag
                    key={jobType}
                    value={jobType}
                    className={`cursor-pointer ${selectedJobType === jobType ? 'bg-teal-500 text-white' : 'bg-sky-100 text-teal-800'}`}
                    onClick={() => setSelectedJobType(selectedJobType === jobType ? null : jobType)}
                />
            ))}
        </div>
    );
};

export default FilterTags;
